<script setup>
import { useDisplay } from 'vuetify/lib/framework.mjs'

const { smAndDown } = useDisplay()

const state = reactive({
  drawer: false,
  links: [
    {
      text: 'What is the Gospel',
      href: '/faith/what-is-the-gospel',
    },
    {
      text: 'About',
      children: [
        {
          text: 'Our Story',
          href: '/about/our-story',
        },
        {
          text: 'What We Believe',
          href: '/about/what-we-believe',
        },
        {
          text: 'Plan Your Visit',
          href: '/about/plan-your-visit',
        },
      ],
    },

    {
      text: 'Ministries',
      children: [
        {
          text: 'Children\'s Ministries',
          href: '/ministries/children',
        },
        {
          text: 'Youth Group',
          href: '/ministries/youth',
        },
        {
          text: 'Discipleship',
          href: '/ministries/discipleship',
        },
        {
          text: 'FRC Day Camp',
          href: '/ministries/day-camp',
        },
      ],
    },
    {
      text: 'Members',
      href: '/members',
    },
    {
      text: 'Contact',
      href: '/contact',
    },
    {
      text: 'Give',
      href: '/give',
    },
  ],
})
</script>

<template>
  <v-app>
    <v-layout>
      <!-- <v-system-bar
        color="red"
        window
        style="justify-content: center;"
      >
        Site Currently in Development
      </v-system-bar> -->
      <v-app-bar height="80" class="position-fixed">
        <v-container class="pa-0 d-flex align-center">
          <NuxtLink to="/" class="d-flex align-center">
            <NuxtImg
              alt="First Reformed Church"
              class="ml-4"
              src="/img/logo.png"
              height="55px"
              contain
            />
          </NuxtLink>

          <v-spacer />

          <v-app-bar-nav-icon
            v-if="smAndDown"
            class="mr-3"
            data-aos="fade-left"
            data-aos-delay="250"
            @click="state.drawer = !state.drawer"
          />

          <template v-else>
            <template
              v-for="(link, idx) in state.links"
              :key="link.href"
            >
              <v-menu
                v-if="link.children"
                open-on-hover
                open-delay="0"
              >
                <template #activator="{ props }">
                  <div
                    data-aos="fade-down"
                    :data-aos-delay="100 * idx"
                  >
                    <v-btn
                      v-bind="props"
                      append-icon="mdi-triangle-small-down"
                      class="text-capitalize"
                    >
                      {{ link.text }}
                    </v-btn>
                  </div>
                </template>

                <v-list>
                  <v-list-item
                    v-for="child in link.children"
                    :key="child.href"
                    :to="child.href"
                    role="option"
                  >
                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div
                v-else
                data-aos="fade-down"
                :data-aos-delay="100 * idx"
              >
                <v-btn
                  :to="link.href"
                  class="text-capitalize"
                >
                  {{ link.text }}
                </v-btn>
              </div>
            </template>
          </template>
        </v-container>
      </v-app-bar>

      <v-navigation-drawer
        v-model="state.drawer"
        location="top"
        class="mobileNav"
        temporary
      >
        <v-list v-if="smAndDown">
          <template
            v-for="link in state.links"
            :key="link.href"
          >
            <v-list-group
              v-if="link.children"
            >
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :title="link.text"
                />
              </template>

              <v-list-item
                v-for="child in link.children"
                :key="child.href"
                :to="child.href"
                :title="child.text"
              />
            </v-list-group>
            <v-list-item
              v-else
              role="option"
              :to="link.href"
            >
              {{ link.text }}
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-main style="padding-top: 80px">
        <slot />

        <v-footer
          border="t"
          class="footer"
          style="width: 100%"
        >
          <v-container>
            <v-row
              align="center"
              justify="center"
              justify-md="space-around"
            >
              <v-col
                cols="12"
                md="auto"
                class="d-flex flex-column justify-center text-center"
              >
                <NuxtLink
                  to="/"
                  class="pb-2 mx-auto"
                >
                  <v-img
                    aspect-ratio="2.481481481481481"
                    alt="First Reformed Church"
                    src="/img/logo.png"
                    width="175px"
                    height="55px"
                    contain
                  />
                </NuxtLink>

                <address class="text-subtitle-2 font-normal">
                  1136 Vansickle Road N. <br>
                  St. Catharines, ON
                </address>
              </v-col>

              <v-col
                cols="12"
                md="auto"
                class="py-0"
              >
                <v-list
                  class="py-0"
                  density="compact"
                  :role="null"
                >
                  <v-list-item
                    class="text-center text-md-left"
                    title="What We Believe"
                    variant="plain"
                    :active="false"
                    :ripple="false"
                    to="/about/what-we-believe"
                  />

                  <v-list-item
                    class="text-center text-md-left"
                    title="Plan Your Visit"
                    variant="plain"
                    :active="false"
                    :ripple="false"
                    to="/about/plan-your-visit"
                  />

                  <v-list-item
                    class="text-center text-md-left"
                    title="Our Story"
                    variant="plain"
                    :active="false"
                    :ripple="false"
                    to="/about/our-story"
                  />
                </v-list>
              </v-col>

              <v-col
                cols="12"
                md="auto"
                class="py-0"
              >
                <v-list
                  class="py-0"
                  density="compact"
                  :role="null"
                >
                  <v-list-item
                    class="text-center text-md-left"
                    title="Contact"
                    variant="plain"
                    :active="false"
                    :ripple="false"
                    to="/contact"
                  />
                  <v-list-item
                    class="text-center text-md-left"
                    title="Accessibility"
                    variant="plain"
                    :active="false"
                    :ripple="false"
                    :to="{ path: '/privacy', hash: '#accessibility' }"
                  />
                  <v-list-item
                    class="text-center text-md-left"
                    title="Privacy"
                    variant="plain"
                    :active="false"
                    :ripple="false"
                    to="/privacy"
                  />
                </v-list>
              </v-col>

              <v-col
                cols="12"
                md="auto"
                class="text-center text-md-left"
              >
                <div class="pb-1">
                  <v-btn
                    icon="mdi-facebook"
                    variant="text"
                    href="https://www.facebook.com/firstrefchurch"
                    target="_blank"
                    aria-label="Facebook"
                  />
                  <v-btn
                    icon="mdi-instagram"
                    variant="text"
                    href="https://www.instagram.com/firstrefchurch"
                    target="_blank"
                    aria-label="Instagram"
                  />
                  <v-btn
                    icon="mdi-youtube"
                    variant="text"
                    href="https://www.youtube.com/@FirstRefChurch"
                    target="_blank"
                    aria-label="Youtube"
                  />
                  <v-btn
                    icon="mdi-spotify"
                    variant="text"
                    href=""
                    target="_blank"
                    aria-label="Spotify"
                  />
                </div>
                <div class="pb-3">
                  <v-icon
                    icon="mdi-phone"
                    size="small"
                    start
                  />
                  <a
                    href="tel:905-685-1441"
                    class="link-unstyled"
                  >
                    905-685-1441
                  </a>
                </div>
                <div>
                  <v-icon
                    icon="mdi-email"
                    size="small"
                    start
                  />
                  <a
                    href="mailto:info@firstrefchurch.ca"
                    class="link-unstyled"
                  >
                    info@firstrefchurch.ca
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <div
            class="bg-grey-darken-4"
            style="width: 100%"
          >
            <v-container class="text-center py-2">
              ©{{ new Date().getFullYear() }} - First Reformed Church of St. Catharines
            </v-container>
          </div>
        </v-footer>
      </v-main>
    </v-layout>

    <ConfirmDialog />
  </v-app>
</template>

<style lang="scss">
.v-btn__append {
  margin-left: 0
}
.mobileNav {
  height: auto !important;
  position: fixed !important;
}

.footer {
  border-left: 0;
  border-right: 0;
  display: flex;
  flex-direction: column;
  padding: 0;

  .font-normal {
    font-style: normal;
  }

  .link-unstyled,
  .link-unstyled:active,
  .link-unstyled:hover,
  .link-unstyled:focus {
    text-decoration: none;
    color: #000
  }
}
</style>
